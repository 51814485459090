import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    constructor() {}

    private tooltipShow: boolean = true;

    public setTooltipShow(_tooltipShow: boolean): void
    {
        this.tooltipShow = _tooltipShow;
    }
    
    public getTooltipShow(): boolean
    {
        return this.tooltipShow;
    }
}