import { Component, NgZone } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { UserChat } from './models/chat.model';
import { first } from 'rxjs/operators';
import { DbService } from './services/db.service';
import { AlertService } from 'src/app/services/alert.service';
import { RouterUrlService } from './services/router-url.service';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import OneSignal from 'onesignal-cordova-plugin';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { MembershipService } from './services/membership.service';
import { LoadingService } from './services/load.service';
import  channelService  from './services/channel.service';
import { PlatformchkService } from './services/platformchk.service';

const TIMEPERIODTOEXIT: number = 2000;
declare const cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  lastTimeBackPress: number = 0;
  constructor(
    public platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navc: NavController,
    private router: Router,
    private db: DbService,
    private alert: AlertService,
    private routerURL: RouterUrlService,
    private ngZone: NgZone,
    private deeplinks: Deeplinks,
    private store: InAppPurchase2,
    private membership: MembershipService,
    private load: LoadingService,
    public pc: PlatformchkService,
  ) {
    this.initializeApp();
    this.backbutton();
    this.routerURL.initRouter();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();

      if (this.platform.is('android')) {
        this.statusBar.overlaysWebView(false);
        this.statusBar.styleDefault(); //text-color black 일때
        this.statusBar.backgroundColorByHexString('#fff');
      } else {
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#fff');
        this.statusBar.styleDefault();
      }

      if (this.platform.is('cordova')) {
        // 인앱 결제시 상품 등록 및 확인
        this.storeSetting();
        this.storeListener();

        this.ngZone.run(() => {
          this.setOnesignal(); // 원시그널 세팅
          this.deeplink();
        });
      }

      this.splashScreen.hide();

      channelService.loadScript();
      channelService.boot({
        "pluginKey": "181852e5-b202-4fc0-a5b6-faedd8cf6a31",
      });
    });
    
  }

  setOnesignal() {
    OneSignal.setAppId('e60e6253-4046-42ee-8b7e-d93edf6cf2b2');
    OneSignal.promptForPushNotificationsWithUserResponse();

    OneSignal.setNotificationOpenedHandler((jsonData: any) => {
      // console.log('pushdata:'+ JSON.stringify(jsonData));
      const data = jsonData.notification.additionalData;
      this.goNav(data);
    });
  }
  deeplink() {
    // 앱이 처음 켜졌을때
    cordova.plugins.firebase.dynamiclinks
      .getDynamicLink()
      .then(data => {
        if (data) {
          setTimeout(() => {
            const url: string = data.deepLink;
            if (localStorage.getItem('userId')) {
              if (url.indexOf('casting-detail') > -1) {
                this.gotoCastingDetail(url, data.castingId);
              } else if(url.indexOf('square-detail') > -1) {
                this.gotoSquareDetail('square-detail', url);
              } else if(url.indexOf('casting-register') > -1) {
                this.gotoCastingRegister('casting-register', url);
              } else if(url.indexOf('casting') > -1) {
                this.gotoCasting('casting', url);
              }
            } else {
              if(url.indexOf('square-detail') > -1) {
                this.gotoSquareDetail('visitor-square-detail', url);
              } else if(url.indexOf('casting-register') > -1) {
                this.gotoCastingRegister('casting-register', url);
              } else if(url.indexOf('casting') > -1) {
                this.gotoCasting('visitor-casting', url);
              } else {
                this.navc.navigateRoot('/login');
              }
            }
          }, 2000);
        } else {
          // 아무것도 하면 안됨
        }
      })
      .catch(err => {});

    // 앱이 켜있는 상태에서 딥링크를 열었을때
    cordova.plugins.firebase.dynamiclinks.onDynamicLink(
      data => {
        const url: string = data.deepLink;
        if (localStorage.getItem('userId')) {
          if (url.indexOf('casting-detail') > -1) {
            this.gotoCastingDetail(url, data.castingId);
          } else if(url.indexOf('square-detail') > -1) {
            this.gotoSquareDetail('square-detail', url);
          } else if(url.indexOf('casting-register') > -1) {
            this.gotoCastingRegister('casting-register', url);
          } else if(url.indexOf('casting') > -1) {
            this.gotoCasting('casting', url);
          }
        } else {
          if(url.indexOf('square-detail') > -1) {
            this.gotoSquareDetail('visitor-square-detail', url);
          } else if(url.indexOf('casting-register') > -1) {
            this.gotoCastingRegister('casting-register', url);
          } else if(url.indexOf('casting') > -1) {
            this.gotoCasting('visitor-casting', url);
          } else {
            this.navc.navigateRoot('/login');
          }
        }
      },
      err => {}
    );

    this.deeplinks
      .route({
        '/casting-detail': '/casting-detail',
        '/square-detail': '/square-detail',
      })
      .subscribe(
        match => {
          const internalPath = `${match.$route}?${match.$link.queryString}`;
          this.ngZone.run(() => {
            if (localStorage.getItem('userId')) {
              this.router.navigateByUrl(internalPath); //뭘까..

              if (match.$route.indexOf('square-detail') > -1) {
                this.gotoSquareDetail('square-detail', match.$link.queryString);
              } else {
                let castingId = match.$link.queryString.split('=')[1];

                this.gotoCastingDetail(match.$route, castingId);
              }
            }
          });
        },
        error => {
          const internalPath = `${error.$link.path}`;
          this.ngZone.run(() => {
            if (localStorage.getItem('userId')) {
              if (internalPath.indexOf('square-detail') > -1) {
                this.gotoSquareDetail('square-detail', internalPath);
              } else if (internalPath.indexOf('cel-event-detail') > -1 || internalPath.indexOf('casting-detail') > -1) {
                let castingId = internalPath.split('=')[1];

                this.gotoCastingDetail('casting-detail', castingId);
              }
            }
          });
        }
      );
  }

  gotoCastingDetail(url: string, castingId): void {
    const userType: string = localStorage.getItem('userType');

    if (userType == 'user' || userType == 'company') {
      // 유저일 경우 이 페이지로 이동
      this.navc.navigateForward('cel-event-detail', {
        queryParams: {
          castingId: castingId,
        },
      });
    } else {
      // 셀럽일 경우 이 페이지로 이동
      this.navc.navigateForward('casting-detail', {
        queryParams: {
          castingId: castingId,
        },
      });
    }
  }

  gotoSquareDetail(path: string, url: string): void {
    const squareId: string = url.substring(url.lastIndexOf('=') + 1);
    this.navc.navigateForward(path, {
      queryParams: {
        squareId,
      },
    });
  }

  gotoCasting(path: string, url: string): void {
    const celebId: string = url.substring(url.lastIndexOf('=') + 1);
    this.navc.navigateForward(path, {
      queryParams: {
        celebId: celebId,
      },
    });
  }

  gotoCastingRegister(path: string, url: string): void {
    this.navc.navigateForward(path);
  }

  async goNav(data: any) {
    const type = data.type;
    const userType: string = localStorage.getItem('userType');

    switch (type) {
      case 'chat':
        const url: string = this.router.url;
        // 현재 채팅방에 있는데 같은 채팅방을 누른경우!
        // 다른채팅방에 있거나 다른 페이지에 있을 경우에만 이동 가능
        if (url.indexOf('message-detail') == -1 && url.indexOf(`chatId=${data.chatId}`) == -1) {
          this.goMessageDetail(data.chatId);
        }

        break;
      case 'event':
        this.navc.navigateForward(['/event'], {
          queryParams: {
            eventId: data.eventId,
          },
        });
        break;
      case 'notice':
        this.navc.navigateForward(['/notice'], {});
        break;
      case 'friend':
        if (userType == 'user' || userType == 'company') {
          this.navc.navigateRoot(['/user-tabs/home-friend'], {});
        } else {
          this.navc.navigateRoot(['/cel-tabs/friend-cel'], {});
        }
        break;

      case 'complete':
      case 'confirm':
      case 'inquiry':
      case 'edit':
      case 'reply':
      case 'contractDoc':
      case 'contractDocEdit':
      case 'contractDocConfirm': {
        this.goCasting(data.castingId);
        break;
      }
      case 'videocall': {
        this.navc.navigateForward('/videocall', {
          animated: false,
          queryParams: {
            type: 'user',
            heartUsageId: data.heartUsageId,
            celebId: data.celebId,
          },
        });
      }
      case 'castingSetting': {
        this.navc.navigateForward('/rightfee-setup', {
          animated: false,
          queryParams: {
            celebId: data.celebId,
          },
        });
      }
      case 'castingOpen': {
        this.navc.navigateForward('/casting', {
          animated: false,
          queryParams: {
            celebId: data.celebId,
          },
        });
      }

      default:
        return;
    }
  }

  // 캐스팅 상세 이동
  goCasting(castingId: string): void {
    const userType: string = localStorage.getItem('userType');

    if (userType == 'user' || userType == 'company') {
      this.navc.navigateForward(['/cel-event-detail'], {
        queryParams: {
          castingId,
        },
      });
    } else {
      this.navc.navigateForward(['/casting-detail'], {
        queryParams: {
          castingId,
        },
      });
    }
  }

  // 채팅방 상세 이동
  async goMessageDetail(chatId: string): Promise<void> {
    const userChat: UserChat = await this.db.doc$(`userChat/${chatId}`).pipe(first()).toPromise();
    this.navc.navigateForward('/message-detail', {
      queryParams: {
        celebId: userChat.celebId,
        dateCreated: userChat.dateCreated,
        chatId: chatId,
      },
    });
  }

  private backbutton(): void {
    this.platform.backButton.subscribeWithPriority(0, async () => {
      let url = this.router.url;
      if (url.indexOf('waiting-approval') > -1) {
        this.exitApp();
      } else if (url.indexOf('cel-event-detail') > -1 || url.indexOf('casting-detail') > -1) {
        const userType: string = localStorage.getItem('userType');
        if (userType == 'user' || userType == 'company') {
          this.navc.navigateBack(['/user-tabs/casting'], {});
        } else {
          this.navc.navigateBack(['/cel-tabs/casting'], {});
        }
      } else if (url.indexOf('square-detail') > -1) {
        this.navc.navigateBack(['/user-tabs/square'], {});
      } else {
        switch (url) {
          case '/':
          case '/waiting-approval':
          case '/user-tabs':
          case '/user-tabs/home-friend':
          case '/user-tabs/casting':
          case '/user-tabs/message-list':
          case '/user-tabs/square':
          case '/user-tabs/more':
          case '/cel-tabs':
          case '/cel-tabs/friend-cel':
          case '/cel-tabs/casting':
          case '/cel-tabs/message-cel-list':
          case '/cel-tabs/square-cel':
          case '/cel-tabs/more-cel': 
          case '/visitor-tabs/':
          case '/visitor-tabs/visitor-main':
          case '/visitor-tabs/visitor-casting':
          case '/visitor-tabs/visitor-message-list':
          case '/visitor-tabs/visitor-square':
          case '/visitor-tabs/visitor-more': 
          {
            this.exitApp();
            break;
          }
          default: {
            this.navc.pop();
            break;
          }
        }
      }
    });
  }

  private exitApp(): void {
    if (new Date().getTime() - this.lastTimeBackPress < TIMEPERIODTOEXIT) {
      navigator['app'].exitApp();
    } else {
      this.alert.toast('다시 한번 누르면 종료됩니다.');
      this.lastTimeBackPress = new Date().getTime();
    }
  }

  // 상품을 세팅하기 위해서 사용하는 코드
  storeSetting(): void {
    this.store.error((e: { code: number; message: string }) => {
      console.error(`[Store] error handler: ${e.message} (${e.code})`);
    });

    this.store.log = {
      error: (message: string | object) => console.log('[Store] error ' + message),
      warn: (message: string | object) => console.log('[Store] warn ' + message),
      info: (message: string | object) => console.log('[Store] info ' + message),
      debug: (message: string | object) => console.log('[Store] debug ' + message),
    };

    this.store.verbosity = this.store.DEBUG;

    // 등록한 상품 하나하나에 대한 정보들
    // 처음 호출하기 전에 정의하는데 사용한다. (register)
    // 소모품 결제
    this.store.register({
      id: 'heart50',
      type: this.store.CONSUMABLE,
    });
    this.store.register({
      id: 'heart100',
      type: this.store.CONSUMABLE,
    });
    this.store.register({
      id: 'heart200',
      type: this.store.CONSUMABLE,
    });
    this.store.register({
      id: 'heart500',
      type: this.store.CONSUMABLE,
    });
    this.store.register({
      id: 'heart1000',
      type: this.store.CONSUMABLE,
    });
    this.store.register({
      id: 'heart2000',
      type: this.store.CONSUMABLE,
    });
    if (this.platform.is('android')) {
      this.store.register({
        id: 'heart700',
        type: this.store.CONSUMABLE,
      });
    }
  }

  async storeListener(): Promise<void> {
    // 각각의 구글과 애플에서 등록한 상품 id
    // isSUb => 앱이 정기결제 상품일 때 true
    const membershipIds: Array<object> = [
      { name: 'heart50', isSub: false },
      { name: 'heart100', isSub: false },
      { name: 'heart200', isSub: false },
      { name: 'heart500', isSub: false },
      { name: 'heart1000', isSub: false },
      { name: 'heart2000', isSub: false },
      { name: 'heart700', isSub: false },
    ];

    membershipIds.forEach((membership: any, index: number) => {
      // 기본적으로 등록되어야 하는 리스너들입니다.
      // 결제가 완료됐을 경우 (결제에 대한 승인이 되었지만 인정이 되지 않아서 자동으로 환불처리가 될 수 있음!)
      this.store.when(membership.name).approved(p => p.verify());

      // 검증이 되었을 경우
      this.store.when(membership.name).verified(p => {
        p.finish();
      });

      // 검증에 실패하였을 경우
      this.store.when(membership.name).unverified(p => p);

      // 제품의 필드 중의 하나가 변경될 때 마다 이벤트가 작동
      // (예를 들어 owned 상태 변화 등)
      this.store.when(membership.name).updated(p => {
        if (p.loaded && p.valid && p.state === this.store.APPROVED) {
          // 결제에 대한 인정 처리를 하는 것! 완벽히 결제 마무리!
          // 스토어에서 거래를 종료하기 전에 구매가 배송되었는지 학인을 해야한다.
          p.finish();
        }
      });

      // 결제 취소 리스너
      // 인앱 결제창에서 취소버튼을 눌러서 취소 하였을 경우
      this.store.when(membership.name).cancelled(p => {
        const cancel = JSON.parse(JSON.stringify(p));

        this.load.hide();

        // 1개월 멤버십 취소버튼을 눌렀을 경우 alert창
        if (cancel.state !== 'valid') {
          // 멤버십 서비스의 orderState 상태 변경
          // 결제 취소로 이루어지지 않았으니 false 처리
          this.membership.setState = false;
          this.membership.setPurchaseState = false;
          this.alert.okBtn('스토어 결제가 취소되었습니다.');
        }
      });
      // 결제 완료 리스너
      this.store.when(membership.name).finished(async (product: any) => {
        if (this.membership.getState) {
          this.membership
            .paymentSuccess(product)
            .then(() => {
              this.load.hide();
            })
            .catch(() => {
              this.membership.setPurchaseState = false;
              this.alert.presentAlert('결제 실패', '스토어 결제에 실패했습니다. 다시 시도해 주세요.');
            });
          this.membership.setState = false;
          this.membership.setPurchaseState = false;
          this.store.refresh();
        }
      });
    });

    this.store.refresh();
  }
}
