/** @format */

import { Injectable } from '@angular/core';
import { CameraOptions, Camera } from '@awesome-cordova-plugins/camera/ngx';
import * as firebase from 'firebase';
import { Platform } from '@ionic/angular';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import * as moment from 'moment';
import { LoadingService } from './load.service';
import { CommonService } from './common.service';

import { environment } from 'src/environments/environment'; 
firebase.initializeApp(environment.firebaseConfig);

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  public cameraoption: CameraOptions = {
    quality: 60,
    allowEdit: false,
    destinationType: this.camera.DestinationType.DATA_URL,
    encodingType: this.camera.EncodingType.JPEG,
    mediaType: this.camera.MediaType.PICTURE,
    sourceType: this.camera.PictureSourceType.CAMERA,
    correctOrientation: true,
    saveToPhotoAlbum: false,
  };

  public gallery: CameraOptions = {
    quality: 100,

    destinationType: this.camera.DestinationType.DATA_URL,
    encodingType: this.camera.EncodingType.JPEG,
    correctOrientation: true,
    sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
  };

  public galleryVideo: CameraOptions = {
    sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
    destinationType: this.camera.DestinationType.DATA_URL,
    mediaType: this.camera.MediaType.VIDEO,
  };
  uploadProgress = 0;

  // Upload Task
  task: AngularFireUploadTask;
  //Uploaded file List
  constructor(
    public camera: Camera,
    private loading: LoadingService,
    private common: CommonService,
    public platform: Platform,
    public load: LoadingService,
    private storage: AngularFireStorage
  ) {}

  async getCamera(type) {
    return new Promise<any>(resolve => {
      this.camera.getPicture(this.cameraoption).then(async (url: any) => {
        url = 'data:image/jpeg;base64,' + url;
        const name = this.common.generateFilename();

        this.loading.load();
        firebase
          .storage()
          .ref(`/images/${type}/` + name)
          .putString(url, 'data_url')
          .then(v => {
            this.loading.hide();
            let url = `https://storage.googleapis.com/${v.metadata.bucket}/${v.metadata.fullPath}`;
            resolve(url);
          })
          .catch(error => {
            this.loading.hide();
          });
      });
    });
  }

  async getGallery(type) {
    return new Promise<any>(resolve => {
      this.loading.load();
      this.camera
        .getPicture(this.gallery)
        .then(async (url: any) => {
          url = 'data:image/jpeg;base64,' + url;
          const name = this.common.generateFilename();

          firebase
            .storage()
            .ref(`/images/${type}/` + name)
            .putString(url, 'data_url')
            .then(v => {
              let url = `https://storage.googleapis.com/${v.metadata.bucket}/${v.metadata.fullPath}`;
              this.loading.hide();
              resolve(url);
            })
            .catch(error => {
              this.loading.hide();
            });
        })
        .catch(error => {});
    });
  }

  async uploadVideo(userId, video) {
    return new Promise((resolve, reject) => {
      ///비디오 메타데이터 확인 후 hevc이면 얼랫 뛰으로 reject
      const fileName = this.common.generateFilename() + '.mp4';

      this.load.load('동영상을 업로드 중입니다.');
      const uploadTask = firebase
        .storage()
        .ref()
        .child('video/' + userId + '/' + fileName)
        .putString(video, 'data_url');
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {},
        error => {},
        () => {
          setTimeout(() => {
            firebase
              .storage()
              .ref()
              .child('video/' + userId + '/' + fileName)
              .getDownloadURL()
              .then(
                downloadURL => {
                  resolve(downloadURL);
                },
                e => {}
              )
              .then(
                () => {},
                e => {}
              )
              .catch(error => {
                reject(error);
              });
          }, 500);
          this.load.hide();
        }
      );
    });
  }

  delFromStorage(url) {
    return new Promise((resolve, reject) => {
      const ref = this.storage.storage.refFromURL(url);
      ref
        .delete()
        .then(() => {
          resolve('success');
        })
        .catch(() => {
          reject('error');
        });
    });
  }

  onImageChange(event): any {
    return new Promise(resolve => {
      const images = event.target.files[0];

      this.getOrientation(images, orientation => {
        var reader = new FileReader();
        reader.readAsDataURL(images);
        reader.onload = () => {
          var base64 = reader.result;
          this.resetOrientation(base64, orientation, resetBase64Image => {
            var byteString = atob(resetBase64Image.split(',')[1]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            var bb = new Blob([ab], { type: 'image/jpeg' });
            var reader = new FileReader();
            reader.readAsDataURL(bb);
            reader.onloadend = () => {
              var base64data = reader.result;
              var storageRef = firebase.storage().ref();
              var file = base64data + '';
              var fileName = 'image_' + moment().format('x') + '.jpg';
              var upRef = '/images/' + fileName;
              var uploadTask = storageRef.child(upRef).putString(file, 'data_url');
              uploadTask.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                snapshot => {},
                error => {},
                () => {
                  try {
                    uploadTask.then(v => {
                      let url = `https://storage.googleapis.com/${v.metadata.bucket}/${v.metadata.fullPath}`;
                      resolve(url);
                    });
                  } catch (e) {
                    resolve(e);
                  }
                }
              );
            };
          });
        };
        reader.onerror = error => {};
      });
    });
  }

  getOrientation(file, callback) {
    var reader: any, target: EventTarget;
    reader = new FileReader();
    reader.onload = event => {
      var view = new DataView(event.target.result);

      if (view.getUint16(0, false) != 0xffd8) return callback(-2);

      var length = view.byteLength,
        offset = 2;

      while (offset < length) {
        var marker = view.getUint16(offset, false);
        offset += 2;

        if (marker == 0xffe1) {
          if (view.getUint32((offset += 2), false) != 0x45786966) {
            return callback(-1);
          }
          var little = view.getUint16((offset += 6), false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;

          for (var i = 0; i < tags; i++)
            if (view.getUint16(offset + i * 12, little) == 0x0112) return callback(view.getUint16(offset + i * 12 + 8, little));
        } else if ((marker & 0xff00) != 0xff00) break;
        else offset += view.getUint16(offset, false);
      }
      return callback(-1);
    };

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  }

  getBase64(file, orientation) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var base64 = reader.result;
      this.resetOrientation(base64, orientation, resetBase64Image => {
        this.dataURItoBlob(resetBase64Image);
      });
    };
    reader.onerror = error => {};
  }

  resetOrientation(srcBase64, srcOrientation, callback) {
    var img = new Image();

    img.onload = () => {
      var width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL());
    };

    img.src = srcBase64;
  }

  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var bb = new Blob([ab], { type: 'image/jpeg' });
    var reader = new FileReader();
    reader.readAsDataURL(bb);
    reader.onloadend = () => {
      var base64data = reader.result;
      this.saveImage(reader.result);
    };
    return bb;
  }

  saveImage(uploadImage) {
    var storageRef = firebase.storage().ref();
    var file = uploadImage;
    var fileName = 'image_' + moment().format('x') + '.jpg';
    var upRef = '/images/' + fileName;
    var uploadTask = storageRef.child(upRef).putString(file, 'data_url');
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {},
      error => {},
      () => {
        try {
          storageRef
            .child(upRef)
            .getDownloadURL()
            .then(downloadURL => {
              return downloadURL;
            });
        } catch (e) {}
      }
    );
  }
}
