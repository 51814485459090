import { DbService } from './db.service';
import { ModalController, Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { Injectable } from '@angular/core';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { CommonService } from './common.service';
import { take } from 'rxjs/operators';
import { LoadingService } from './load.service';
import { HeartService } from './heart.service';
import { AlertService } from 'src/app/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  // 프리미엄 결제 진행시에 상태처리
  private orderState: boolean = false;
  // 인앱 결제 진행시에 활서화 처리를 하기 위한 스위치
  private purchaseSwitch: boolean = false;

  constructor(
    private db: DbService,
    private platform: Platform,
    public httpc: HttpClient,
    public http: HTTP,
    public modalController: ModalController,
    private store: InAppPurchase2,
    private common: CommonService,
    private loading: LoadingService,
    private heartService: HeartService,
    private alert: AlertService
  ) {}

  get isDesktop(): boolean {
    if ((this.platform.is('desktop') || this.platform.is('mobileweb')) && !this.platform.is('cordova')) {
      return true;
    } else {
      return false;
    }
  }

  get isAndroid(): boolean {
    return this.platform.is('android');
  }

  get getState(): boolean {
    return this.orderState;
  }

  set setState(state: boolean) {
    this.orderState = state;
  }

  get getPurchaseState(): boolean {
    return this.purchaseSwitch;
  }

  set setPurchaseState(state: boolean) {
    this.purchaseSwitch = state;
  }

  async paymentSuccess(product) {
    const userId: string = localStorage.getItem('userId');
    const user = await this.db.doc$(`user/${userId}`).pipe(take(1)).toPromise();
    const type = user.userType != 'celeb' ? user.userType : user.companyType == 'celebCompany' ? 'celebCompany' : 'celeb';

    // 충전 가격은 (price)
    // 핸드폰 기종에 따라!
    const chargePrice: number = parseInt(product.price.replace(/[^0-9]/g, ''));
    let heartNumber: number;
    switch (product.id) {
      case 'heart50':
        heartNumber = 40;
        break;
      case 'heart100':
        heartNumber = 200;
        break;
      case 'heart200':
        heartNumber = 500;
        break;
      case 'heart500':
        heartNumber = 700;
        break;
      case 'heart700':
        heartNumber = 700;
        break;
      case 'heart1000':
        heartNumber = 900;
        break;
      case 'heart2000':
        heartNumber = 4100;
        break;
    }

    this.loading.load();
    const chargeHeart = await this.heartService.addHeart(userId, type, chargePrice, heartNumber);
    this.loading.hide();
    this.modalController.dismiss(heartNumber);
    if (chargeHeart == 'success') {
      this.alert.toast('결제를 완료하였습니다.');
    }
  }
}
