import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { DbService } from './db.service';
import { of } from 'rxjs';
import { map, take, first, switchMap, shareReplay } from 'rxjs/operators';
import * as firebase from 'firebase';
import { CommonService } from './common.service';
import { AlertController, NavController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { LoginChkService } from './login-chk.service';
import { ApiRtcService } from './api-rtc.service';
// import { PushService } from './push.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$;
  userId;

  read = [];

  constructor(
    public afAuth: AngularFireAuth,
    private db: DbService,

    public common: CommonService,
    public alertController: AlertController,
    private alert: AlertService,
    private loginChk: LoginChkService,
    private apirtc: ApiRtcService,
    public navc: NavController // public push: PushService
  ) {
    this.user$ = this.afAuth.authState.pipe(switchMap(user => (user ? db.doc$(`user/${user.uid}`).pipe(shareReplay()) : of(null)))).pipe(
      map((tmp: any) => {
        this.userId = tmp?.uid ? tmp.uid : 'null';
        return tmp;
      })
    );
  }

  getUser() {
    return this.user$.pipe(take(1)).toPromise();
  }

  getUser2() {
    return this.user$.pipe(take(2)).toPromise();
  }

  getUid() {
    return this.userId || '';
  }

  getcallinginfor(uid) {
    return this.db.doc$(`callinginfo/${uid}`).pipe(first()).toPromise();
  }

  userData() {
    return this.user$.pipe(take(1)).toPromise();
  }

  registerUser(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(value.email, value.password).then(
        res => resolve(res),
        err => reject(err)
      );
    });
  }

  resetPassword(email: string) {
    var auth = firebase.auth();
    return auth
      .sendPasswordResetEmail(email)
      .then(() => {})
      .catch(error => {});
  }

  async signOut(): Promise<any> {
    await this.signProcess();
    const firstVisit: string = localStorage.getItem('firstVisit');
    localStorage.clear();
    if(firstVisit && firstVisit == 'Y') {
      localStorage.setItem('firstVisit', 'Y');
    }

    await this.alert.toast('로그아웃하였습니다.');
  }

  async signProcess(): Promise<void> {
    await firebase.auth().signOut();
  }

  changePassword(value) {
    return new Promise((resulve, reject) => {
      this.afAuth.currentUser
        .then(user => {
          user
            .updatePassword(value)
            .then(e => {
              resulve(e);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  loginUser(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(value.email, value.password).then(
        res => resolve(res),
        err => reject(err)
      );
    });
  }

  logoutUser() {
    return new Promise((resolve, reject) => {
      if (this.afAuth.currentUser) {
        this.afAuth
          .signOut()
          .then(() => {
            // 로그아웃 시 unregister (api rtc) 추가
            this.apirtc.unregister();

            const firstVisit: string = localStorage.getItem('firstVisit');
            localStorage.clear();
            if(firstVisit && firstVisit == 'Y') {
              localStorage.setItem('firstVisit', 'Y');
            }

            this.navc.navigateRoot('/login-email');
            resolve('log out');
          })
          .catch(error => {
            reject();
          });
      }
    });
  }

  sendEmailVerificationUser(user) {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        user
          .sendEmailVerification()
          .then(() => {
            resolve('');
          })
          .catch(error => {
            reject();
          });
      }
    });
  }

  exitUser() {
    return new Promise((resolve, reject) => {
      this.afAuth.currentUser.then(user => {
        user
          .delete()
          .then(() => {
            const firstVisit: string = localStorage.getItem('firstVisit');
            localStorage.clear();
            if(firstVisit && firstVisit == 'Y') {
              localStorage.setItem('firstVisit', 'Y');
            }
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  }

  uid() {
    return this.user$
      .pipe(
        take(1),
        map((u: any) => u && u.uid)
      )
      .toPromise();
  }

  async notLoginUser() {
    if (this.userId === null) {
      const alert = await this.alertController.create({
        cssClass: 'alert',
        header: '로그인',
        message: '로그인이 필요한 서비스입니다.<br />로그인 페이지로 이동 하시겠습니까?',
        buttons: [
          {
            text: '취소',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: '확인',
            handler: () => {
              this.navc.navigateForward('login');
            },
          },
        ],
      });

      await alert.present();
    }
  }
}
