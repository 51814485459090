import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class PlatformchkService {
  constructor(private platform: Platform) {}

  public get isDesktop(): boolean {
    if ((this.platform.is('desktop') || this.platform.is('mobileweb')) && !this.platform.is('cordova')) {
      return true;
    } else {
      return false;
    }
  }

  public get isPcWeb(): boolean {
    if ((this.platform.is('desktop') && !this.platform.is('mobileweb')) && !this.platform.is('cordova')) {
      return true;
    } else {
      return false;
    }
  }

  public get isIOS(): boolean {
    if (this.platform.is('ios') && this.platform.is('cordova')) {
      return true;
    } else {
      return false;
    }
  }

  public get isAND(): boolean {
    if (this.platform.is('android') && this.platform.is('cordova')) {
      return true;
    } else {
      return false;
    }
  }
}
