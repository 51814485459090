import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import  channelService  from 'src/app/services/channel.service';

@Injectable({
  providedIn: 'root',
})
export class RouterUrlService {
  public previousURL: string = '';
  public currentURL: string = '';
  
  constructor(private router: Router) {}

  // url 체크
  public initRouter(): void {
    this.channelTalkShow(window.location.href);
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.previousURL = events[0].urlAfterRedirects;
        this.currentURL = events[1].urlAfterRedirects;
        this.channelTalkShow(this.currentURL);
      });
  }

  private channelTalkShow(currentURL: string) {
    if(
      // currentURL.indexOf('/user-tabs/home-friend') > -1 || 
      currentURL.indexOf('/user-tabs/more') > -1 || 
      //currentURL.indexOf('/cel-tabs/friend-cel' ) > -1|| 
      currentURL.indexOf('/cel-tabs/more-cel') > -1 ||
      // currentURL.indexOf('/visitor-tabs/visitor-main') > -1 ||
      currentURL.indexOf('/visitor-tabs/visitor-more') > -1
    ) {
      channelService.showChannelButton();
    }else {
      channelService.hideChannelButton();
      setTimeout(() => {
        channelService.hideChannelButton();
      }, 500);
    }
  }
}
