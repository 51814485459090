import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loading;
  public isLoading = false;
  constructor(public loader: LoadingController) {}

  async load(message?) {
    this.loading = await this.loader.create({
      spinner: 'dots',
      duration: 5000,
      translucent: true,
      cssClass: 'custom-loading',
      backdropDismiss: false,
      message,
    });
    await this.loading.present();
  }

  async loadTime(duration: number, message?: string) {
    this.loading = await this.loader.create({
      spinner: 'dots',
      duration: (duration * 1000),
      translucent: true,
      cssClass: 'custom-loading',
      backdropDismiss: false,
      message,
    });
    await this.loading.present();
  }

  async lognLoad(message?) {
    this.loading = await this.loader.create({
      spinner: 'dots',
      translucent: true,
      cssClass: 'custom-loading',
      backdropDismiss: false,
      message,
    });
    await this.loading.present();
  }

  hide() {
    if (this.loading) {
      this.loading.dismiss();
    }
  }

  async present() {
    this.isLoading = true;
    return await this.loader
      .create({
        cssClass: 'custom-loader-class',
      })
      .then(a => {
        a.present().then(() => {
          if (!this.isLoading) {
            a.dismiss().then(() => {});
          }
        });
      });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loader.dismiss().then(() => {});
  }
}
