import { AlertController, Platform } from '@ionic/angular';
/** @format */

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { DbService } from '../services/db.service';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { Device } from '@ionic-native/device/ngx';
import { PushService } from '../services/push.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowserOptions, InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    public navCtrl: NavController,
    public auth: AuthService,
    public alertController: AlertController,
    private db: DbService,
    public alertService: AlertService,
    private platform: Platform,
    private device: Device,
    private push: PushService,
    private appVersion: AppVersion,
    private iab: InAppBrowser,
  ) {}

  uid;
  ngOnInit(): void {
  }

  loginCheck = false;

  async loginSet(userInfo) {
    await this.push.getPushId().then(async token => {
      await this.db.updateAt(`user/${userInfo.uid}`, { pushId: token.userId, device: this.device.uuid || 'computer', })
    });
    var isOnlineForDatabase = {
      state: 'online',
      last_changed: new Date().getTime(),
      devices: this.device.uuid,
    };
    await this.db.updateAt(`status/${userInfo.uid}`, isOnlineForDatabase);
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    //강제버전업 체크 
    if (this.platform.is('cordova')) {
      const versions = await this.db
        .collection$(`version`, ref => ref.where('use', '==', true).orderBy('dateCreated', 'desc'))
        .pipe(first())
        .toPromise();
      if(versions && versions[0]) {
        const lastVersion = versions[0];
        this.appVersion.getVersionNumber().then(async version => {
          if(this.platform.is('android')) {
            if(parseInt(version.toString().replace(/\./gi, '')) < parseInt(lastVersion.and.replace(/\./gi, ''))) {
              await this.versionUpdate('https://play.google.com/store/apps/details?id=app.muggleinc.lovechain&hl=en_US');
            }
          }
          if(this.platform.is('ios')) {
            if(parseInt(version.toString().replace(/\./gi, '')) < parseInt(lastVersion.ios.replace(/\./gi, ''))) {
              await this.versionUpdate('https://apps.apple.com/kr/app/%ED%8F%AC%EB%A1%B1/id1609202413');
            }
          }
        });
      }
    }

    const uid: string = localStorage.getItem('userId');
    const user = await this.db.doc$(`user/${uid}`).pipe(first()).toPromise();

    // 로그인 안되어 있는 회원
    if (!uid || !user.uid) {
      // 로그인 가능한 링크로 올때 로그인 안했으면 비회원용 링크로 보내기 (파라메터 포함)
      //main
      if(next.url[0]?.path == 'user-tabs' || next.url[0]?.path == 'cel-tabs') {
        this.navCtrl.navigateRoot('/visitor-tabs/visitor-main', { queryParams: next.queryParams });
        return false;
      }
      //알람
      if(next.url[0]?.path == 'alarm') {
        this.navCtrl.navigateRoot('/visitor-alarm', { queryParams: next.queryParams });
        return false;
      }
      //캐스팅 상세
      if(next.url[0]?.path == 'casting') {
        this.navCtrl.navigateRoot('/visitor-casting', { queryParams: next.queryParams });
        return false;
      }
      //섭외 목록
      if(next.url[0]?.path == 'event-list') {
        this.navCtrl.navigateRoot('/visitor-casting-list', { queryParams: {castingType: 'off', castingTitle: next.queryParams?.castingType } });
        return false;
      }
      //검색::카테고리 클릭
      if(next.url[0]?.path == 'friend-category') {
        this.navCtrl.navigateRoot('/visitor-friend-category', { queryParams: next.queryParams });
        return false;
      }
      //채팅신청
      if(next.url[0]?.path == 'friendtalk-ask') {
        this.navCtrl.navigateRoot('/visitor-friendtalk-ask', { queryParams: next.queryParams });
        return false;
      }
      //검색
      if(next.url[0]?.path == 'search') {
        this.navCtrl.navigateRoot('/visitor-search', { queryParams: next.queryParams });
        return false;
      }
      //팬캠 상세
      if(next.url[0]?.path == 'square-detail') {
        this.navCtrl.navigateRoot('/visitor-square-detail', { queryParams: next.queryParams });
        return false;
      }

      const firstVisit: string = localStorage.getItem('firstVisit');
      if(firstVisit && firstVisit == 'Y') {
        this.navCtrl.navigateRoot('login');
        return false;
      }else {
        localStorage.setItem('firstVisit', 'Y');
        this.navCtrl.navigateRoot('/visitor-tabs/visitor-main');
        return false;
      }
    }

    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
      if (user.dateCreated && user?.device !== this.device.uuid && user.device) {
        await this.alertService
          .cancelOkBtn(
            '해당 계정이 현재 접속 중입니다. 계속 로그인을 진행하시겠습니까?',
            '취소',
            '확인',
            ''
          )
          .then(async ok => {
            if (ok) {
              await this.loginSet(user);
            }else{
              const firstVisit: string = localStorage.getItem('firstVisit');
              localStorage.clear();
              if(firstVisit && firstVisit == 'Y') {
                localStorage.setItem('firstVisit', 'Y');
              }
              this.loginCheck = true;
            }
          });
      }
      if(this.loginCheck === true) {
        this.navCtrl.navigateRoot('login');
        return false;
      }
    }
    
    if (user.celebApply && !user.celebApproved && !user.celebConfirmed) {
      this.navCtrl.navigateRoot('/waiting-approval', {
        queryParams: {
          type: 'celeb',
        },
      });
      return false;
    } else if (user.companyApply && !user.companyApproved && !user.companyConfirmed) {
      this.navCtrl.navigateRoot('/waiting-approval', {
        queryParams: {
          type: 'company',
        },
      });
      return false;
    } else {
      localStorage.setItem('userType', user.userType);
      if (user.userType == 'company') {
        localStorage.setItem('companyType', user.companyType);
      }

      if (user.userType == 'company' && next.url.length < 1) {
        if (user.companyType == 'celebCompany') {
          this.navCtrl.navigateRoot('/cel-tabs/friend-cel');
          return false;
        } else {
          this.navCtrl.navigateRoot('/user-tabs/home-friend');
          return false;
        }
      }

      if (user.userType == 'celeb' && next.url.length < 1) {
        this.navCtrl.navigateRoot('/cel-tabs/friend-cel');
        return false;
      }

      if (user.userType == 'user' && next.url.length < 1) {
        this.navCtrl.navigateRoot('/user-tabs/home-friend');
        return false;
      }
      return true;
    }
  }

  // 강제업데이트 알럿
  async versionUpdate(url) {
    const alert = await this.alertController.create({
      cssClass: 'choice-alert ok',
      message: '업데이트가 필요합니다.',
      backdropDismiss: false,
      buttons: [
        {
          text: '스토어로 이동하기',
          handler: () => {
            if (this.platform.is('cordova') && this.platform.is('android')) {
              this.iab.create(url, '_system', {
                location: 'yes',
                clearcache: 'yes',
                hideurlbar: 'yes',
                zoom: 'no',
                presentationstyle: 'fullscreen',
                transitionstyle: 'fliphorizontal',
                closebuttoncaption: '완료',
                closebuttoncolor: '#ffffff',
                hidenavigationbuttons: 'no',
              }).show();
            } else if (this.platform.is('cordova') && this.platform.is('ios')) {
              this.iab.create(url, '_system', {
                location: 'no',
                clearcache: 'yes',
                hideurlbar: 'no',
                zoom: 'no',
                presentationstyle: 'fullscreen',
                hidenavigationbuttons: 'no',
                toolbarposition: 'top',
                closebuttoncaption: '완료',
              })
              .show();
            } else {
              window.open(url);
            }
            return false;
          },
        },
      ],
    });
    await alert.present();
  }
}
