import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Device } from '@ionic-native/device/ngx';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { firestore } from 'firebase';
import { first } from 'rxjs/operators';
import { User } from '../models/user.model';
import { ApiRtcService } from './api-rtc.service';
import { DbService } from './db.service';
import { LoginChkEventService } from './login-chk-event.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from './load.service';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase';
import { PushService } from './push.service';
import moment from 'moment';

// name of user collection
const USERCOLLECTION: string = 'user';

@Injectable({
  providedIn: 'root',
})
export class LoginChkService {
  initDevice;
  userId: string = localStorage.getItem('userId');
  sub;

  constructor(
    private device: Device,
    private alertCtrl: AlertController,
    private db: DbService,
    private navc: NavController,
    public afAuth: AngularFireAuth,
    private platform: Platform,
    private es: LoginChkEventService,
    private apirtc: ApiRtcService,
    private loading: LoadingService,
    public http: HttpClient,
    private push: PushService,
  ) {
    this.unSubscribe();
  }

  // 다중로그인중인지 체크!

  userSub;
  async checkLogin(): Promise<void | boolean> {
    this.userSub = this.db.doc$(`user/${localStorage.getItem('userId')}`).subscribe(async data => {
      if (!this.isDesktop && data.dateCreated && data?.device !== this.device.uuid && data.device) {
        await this.afAuth.signOut();
        try {
          // 비정상적인 apirtc 테스트 시 error
          this.apirtc.unregister();
          this.alreadyOnline();
        } catch {
          this.alreadyOnline();
        }
      }

      if(data.blockList) {
        const block = data.blockList.slice(-1)[0];
        if(block.blocked && block.sdate && block.edate) {
          const nDate = new Date(moment().format('YYYY-MM-DD')).getTime() / 1000;
          const sDate = new Date(moment(block.sdate).format('YYYY-MM-DD')).getTime() / 1000;
          const eDate = new Date(moment(block.edate).format('YYYY-MM-DD')).getTime() / 1000;
          if(nDate >= sDate && nDate <= eDate) {
            const alert = await this.alertCtrl.create({
              cssClass: 'choice-alert ok',
              message: '신고요청에 의해 해당 계정을 강제 종료합니다. 이의신청은 고객센터로 문의해주세요.',
              backdropDismiss: false,
              buttons: [
                {
                  text: '확인',
                  handler: () => {
                    this.alertCtrl.dismiss();
                    const firstVisit: string = localStorage.getItem('firstVisit');
                    localStorage.clear();
                    if(firstVisit && firstVisit == 'Y') {
                      localStorage.setItem('firstVisit', 'Y');
                    }
    
                    setTimeout(() => {
                      this.alertSwitch = false;
                      this.userSub.unsubscribe();
                    }, 3000);
                    this.navc.navigateRoot(['/'], {
                      animated: true,
                      animationDirection: 'back',
                    });
                  },
                },
              ],
            });
    
            await alert.present();
          }
        }
      }
    });
  }

  // 로그인 페이지에 올 경우 unsubscribe
  unSubscribe(): void {
    this.es.getObservable().subscribe(data => {
      if (this.userSub) {
        this.userSub.unsubscribe();
      }
    });
  }

  // 오브젝트가 빈 값인지 체크
  private checkEmpty(obj: any): boolean {
    const objectKeys: Array<string> = Object.keys(obj);

    // 오브젝트가 빈 값이라는것
    if (objectKeys.length == 1) {
      return true;
    }

    return false;
  }

  updateSwitch: boolean = false;
  updateDeviceId(): void | boolean {
    if (this.updateSwitch) {
      return false;
    }
    this.db.updateAt(`${USERCOLLECTION}/${this.userId}`, { device: this.initDevice });
    this.updateSwitch = true;
  }

  // 로그아웃 시 offline 업데이트
  async setLogoutInfo(): Promise<void> {
    var isOfflineForDatabase = {
      state: 'offline',
      last_changed: firestore.FieldValue.serverTimestamp,
    };
    this.db.updateAt(`status/${this.userId}`, isOfflineForDatabase);
  }

  /// 로그인 정보 업데이트
  async setLoginInfo(): Promise<void> {
    var isOnlineForDatabase = {
      state: 'online',
      last_changed: new Date().getTime(),
      devices: this.device.uuid,
    };

    this.db.updateAt(`status/${this.userId}`, isOnlineForDatabase);
  }

  // 중복 로그인 경고창
  alertSwitch: boolean = false;
  async alreadyOnline(): Promise<void | boolean> {
    if (this.alertSwitch) {
    } else {
      this.alertSwitch = true;
      const alert = await this.alertCtrl.create({
        cssClass: 'choice-alert ok',
        message: '다른 기기에서 로그인이 되었습니다.',
        backdropDismiss: false,
        buttons: [
          {
            text: '확인',
            handler: async () => {
              this.alertCtrl.dismiss();

              //일반에서 셀럽으로 계정 전환했을때는 원래대로 돌아간다.
              if(localStorage.getItem('originUserId')) {
                const userInfo = await this.db.doc$(`user/${localStorage.getItem('originUserId')}`).pipe(first()).toPromise();
                this.loading.load();
                let loginType = 'forlong';
                if(userInfo.loginType && userInfo.loginType[0]) {
                  loginType = userInfo.loginType[0];
                }
                this.http
                  .post(environment.firebasefFunc.socialLogin, { type: loginType, uid: userInfo.uid })
                  .subscribe((customTokenData: any) => {
                    this.afAuth
                    .signOut()
                    .then(() => {
                      firebase
                        .auth()
                        .signInWithCustomToken(customTokenData.firebase_token)
                        .then(async (authUser: any) => {
                          this.alertSwitch = false;
                          this.userSub.unsubscribe();

                          //로그아웃
                          await this.db.updateAt(`user/${this.userId}`, { pushId: '' }).then(async () => {
                            await this.db.updateAt(`status/${this.userId}`, {
                              state: 'offline',
                            });
                            const firstVisit: string = localStorage.getItem('firstVisit');
                            localStorage.clear();
                            if(firstVisit && firstVisit == 'Y') {
                              localStorage.setItem('firstVisit', 'Y');
                            }
                          });
                          //로그인
                          localStorage.setItem('userId', userInfo.uid);
                          localStorage.setItem('userType', userInfo.userType);
                          localStorage.setItem('companyType', userInfo.companyType);

                          await this.push.getPushId().then(async token => {
                            await this.db.updateAt(`user/${userInfo.uid}`, { pushId: token.userId, device: this.device.uuid || 'computer', })
                          });
                          var isOnlineForDatabase = {
                            state: 'online',
                            last_changed: new Date().getTime(),
                            devices: this.device.uuid,
                          };
                          await this.db.updateAt(`status/${userInfo.uid}`, isOnlineForDatabase);
                          if (userInfo.userType == 'user' || userInfo.userType == 'company') {
                            this.navc.navigateRoot('/user-tabs/home-friend').then(() => { window.location.reload(); });
                          }else {
                            this.navc.navigateRoot('/cel-tabs/friend-cel').then(() => { window.location.reload(); });;
                          }
                          this.loading.hide();
                        })
                        .catch(error => {
                        });
                      });
                });

              }else {
                const firstVisit: string = localStorage.getItem('firstVisit');
                localStorage.clear();
                if(firstVisit && firstVisit == 'Y') {
                  localStorage.setItem('firstVisit', 'Y');
                }
                setTimeout(() => {
                  this.alertSwitch = false;
                  this.userSub.unsubscribe();
                }, 3000);
                this.navc.navigateRoot(['/'], {
                  animated: true,
                  animationDirection: 'back',
                });
              }
            },
          },
        ],
      });

      await alert.present();
    }
  }

  public get isDesktop(): boolean {
    if ((this.platform.is('desktop') || this.platform.is('mobileweb')) && !this.platform.is('cordova')) {
      return true;
    } else {
      return false;
    }
  }
}
