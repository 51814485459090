import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, QuerySnapshot } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { combineLatest, defer, of, pipe } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
export const leftJoinDocument = (afs: AngularFirestore, field, collection) => {
  return source =>
    defer(() => {
      // Operator state
      let collectionData;
      const cache = new Map();

      return source.pipe(
        switchMap(data => {
          // Clear mapping on each emitted val ;
          cache.clear();

          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          let i = 0;
          for (const doc of collectionData) {
            // Skip if doc field does not exist or is already in cache
            if (!doc[field] || cache.get(doc[field])) {
              continue;
            }

            // Push doc read to Array
            reads$.push(afs.collection(collection).doc(doc[field]).valueChanges());
            cache.set(doc[field], i);
            i++;
          }

          return reads$.length ? combineLatest(reads$) : of([]);
        }),
        map(joins => {
          return collectionData.map((v, i) => {
            const joinIdx = cache.get(v[field]);
            return { ...v, [field]: joins[joinIdx] || null };
          });
        })
      );
    });
};

export const docJoin = (afs: AngularFirestore, field: string, collection: string, newField: string) => {
  return source =>
    defer(() => {
      let parent;
      return source.pipe(
        switchMap(data => {
          parent = data;
          const id = data[field];

          return afs
            .doc(`${collection}/${id}`)
            .snapshotChanges()
            .pipe(
              map(doc => {
                return doc.payload.data();
              })
            );
        }),
        map(fieldData => {
          return { [newField]: fieldData, ...parent };
        })
      );
    });
};

@Injectable({
  providedIn: 'root',
})

// c r u d
export class DbService {
  constructor(public afs: AngularFirestore, public angularDb: AngularFireDatabase) {}

  collection$(path, query?): any {
    //r
    return this.afs
      .collection(path, query)
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id: id, ...data };
          });
        })
      );
  }

  async collectionGet$(path, query?): Promise<any> {
    //r
    let docs = [];
    await this.afs
      .collection(path, query)
      .get()
      .toPromise()
      .then((actions) => {
        actions.docs.map((doc) => {
          const data: any = doc.data();
          const id = doc.id;
          docs.push({ id: id, ...data });
        });
      });
    return docs;
  }

  doc$(path): any {
    // r
    return this.afs
      .doc(path)
      .snapshotChanges()
      .pipe(
        map(doc => {
          const data: any = doc.payload.data();
          const id = doc.payload.id;
          return { id: id, ...data };
        })
      );
  }

  // ** 기본적인 DB처리 **//
  updateAt(path: string, data: Object): Promise<any> {
    // c, u

    const segments = path.split('/').filter(v => v);
    if (segments.length % 2) {
      // 0 false 1 true
      // Odd is always a collection
      return this.afs.collection(path).add(data);
    } else {
      // Even is always document
      return this.afs.doc(path).set(data, { merge: true });
    }
  }

  delete(path) {
    // d
    return this.afs.doc(path).delete();
  }

  returnMaster() {
    return this.doc$(`master/masterInfo`);
  }

  checkEmail(uid: string, email: string) {
    return this.collection$(`user`, ref => ref.where('uid', '!=', uid).where('email', '==', email).where('exitSwitch', '==', false))
      .pipe(take(1))
      .toPromise();
  }

  checkUserInfo(uid: string, email: string) {
    return this.collection$(`user`, ref =>
      ref.where('uid', '==', uid).where('email', '==', email).where('userType', '==', 'users').where('exitSwitch', '==', false)
    )
      .pipe(take(1))
      .toPromise();
  }

  checkCompany(uid: string, email: string) {
    return this.collection$(`user`, ref =>
      ref.where('uid', '==', uid).where('email', '==', email).where('userType', '==', 'company').where('exitSwitch', '==', false)
    )
      .pipe(take(1))
      .toPromise();
  }
}

export const JoinUserInfo = (afs, feild, productSwitch?) => {
  return source =>
    defer(() => {
      let collectionData;
      const cache = new Map();
      return source.pipe(
        switchMap(data => {
          cache.clear();
          collectionData = data as any[];
          const reads$ = [];
          let i = 0;
          for (const doc of collectionData) {
            if (productSwitch) {
              reads$.push(afs.collection('products').doc(doc[feild]).valueChanges());
            } else {
              // doc = 각 댓글정보
              reads$.push(afs.collection('users').doc(doc[feild]).valueChanges());
            }
            cache.set(doc[feild], i);
            i++;
          }
          return reads$.length ? combineLatest(reads$) : of([]);
        }),
        map(joins => {
          return collectionData.map(v => {
            const joinIdx = cache.get(v[feild]);
            return { ...v, [feild]: joins[joinIdx] };
          });
        })
      );
    });
};
