// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
      apiKey: "AIzaSyDF7D6Jn4kgkxQzg30lGutM2fFXLQpvQ-g",
      authDomain: "forlong-test.firebaseapp.com",
      databaseURL: "https://forlong-test-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "forlong-test",
      storageBucket: "forlong-test.appspot.com",
      messagingSenderId: "881344511789",
      appId: "1:881344511789:web:b8916e6c8a79b999639da4",
      measurementId: "G-8D9R8Y26PE"
  },
  serverURL: 'https://test.forlong.kr',
  sns: {
    googleClientId: '881344511789-bg00pg688nsbvrtl71e2qv915km45pmq.apps.googleusercontent.com',
  },
  firebasefFunc: {
    jwtDecode: 'https://us-central1-forlong-test.cloudfunctions.net/jwtDecode',

    socialSignup: 'https://us-central1-forlong-test.cloudfunctions.net/socialFunctions/socialSignup',
    socialLogin: 'https://us-central1-forlong-test.cloudfunctions.net/socialFunctions/socialLogin',
    socialConnect: 'https://us-central1-forlong-test.cloudfunctions.net/socialFunctions/socialConnect',

    searchLocal2: 'https://asia-northeast3-forlong-test.cloudfunctions.net/searchLocal2',
    searchLocal3: 'https://asia-northeast3-forlong-test.cloudfunctions.net/searchLocal3',

    deleteUser: 'https://us-central1-forlong-test.cloudfunctions.net/socialFunctions/deleteUser',

    autoRefundProcess: 'https://asia-northeast3-forlong-test.cloudfunctions.net/autoRefundProcess',

    paymentStore: 'https://us-central1-forlong-test.cloudfunctions.net/createHeartPayment',
    paymentCasting: 'https://us-central1-forlong-test.cloudfunctions.net/createCastingPayment',

    documentAccessToken: 'https://asia-northeast3-forlong-test.cloudfunctions.net/createEformsignSignature',
    documentCreate: 'https://asia-northeast3-forlong-test.cloudfunctions.net/createEformsignDocument',
    documentCreateAttach: 'https://asia-northeast3-forlong-test.cloudfunctions.net/createEformsignDocumentAttach',

    profileThumbnail: 'https://asia-northeast3-forlong-test.cloudfunctions.net/profileThumbnail',
    getThumbnailFromVideo: 'https://asia-northeast3-forlong-test.cloudfunctions.net/getThumbnailFromVideo',
    getMediaMetadata: 'https://asia-northeast3-forlong-test.cloudfunctions.net/getMediaMetadata',

    wordToHtml: 'https://asia-northeast3-forlong-test.cloudfunctions.net/wordToHtml',
    htmlToWord: 'https://asia-northeast3-forlong-test.cloudfunctions.net/htmlToWord',
    contractDocCreate: 'https://asia-northeast3-forlong-test.cloudfunctions.net/contractDocCreate',

    elevenlabsVoiceMake: 'https://asia-northeast3-forlong-test.cloudfunctions.net/elevenlabsVoiceMake',
    geminiChat: 'https://asia-northeast3-forlong-test.cloudfunctions.net/geminiChat',
    // geminiChat: 'http://127.0.0.1:5001/forlong-test/asia-northeast3/geminiChat',
  },

  chatGptSecret: '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',
  elevenlabsApiKey: 'sk_b249428bd3b564b08df263ce45e698c688151aa0f4620c95',
  didApiKey: 'ZGlvaEBhcnRzdXJuLm5ldA:D91EZEKF95lorhSma6NLq',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
