import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Storage } from '../models/storage.model';
const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() {}

  generateFilename() {
    var length = 15;
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  generateFilename30(): string {
    var length = 30;
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * 이름 변환 함수 정재은 => 정OO, 고현 => 고O, 강다니엘 => 강OOO
   * @param name 변환할 풀네임
   * @param name 이름을 나타낼 문자
   *
   * 2020-01-22 정재은
   */
  getSecretName(name, text) {
    let tmp_name = name.substring(0, 1);
    if (tmp_name.length > 2) {
      for (var i = 1; i < name.length; i++) {
        tmp_name += text;
      }
    } else {
      for (var i = 1; i < 3; i++) {
        tmp_name += text;
      }
    }
    return tmp_name;
  }
  /**
   * object array 중복 제거할 함수
   * @param originalArray 중복제거할 array
   * @param prop 제거 object key 값
   *
   * 2020-01-22 정재은
   */
  removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  /**
   * 오름차순 정렬 함수
   * @param property 정렬 기준 컬럼
   *
   * 2020-01-22 정재은
   */
  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  /**
   * 위도, 경도로 거리를 구하는 함수
   * @param coords1 현재위치 (위도 경도) {latitude: Number, longitude: Number}
   * @param coords2 목적지위치 (위도 경도) {latitude: Number, longitude: Number}
   * @param unit 타입 ('K'일 경우 km, 'N'일 경우 m)
   *
   * 2020-01-22 정재은
   */
  getDistance(facCoords, myCoords, unit) {
    let radlat1 = (Math.PI * facCoords.latitude) / 180;
    let radlat2 = (Math.PI * myCoords.latitude) / 180;
    let theta = facCoords.longitude - myCoords.longitude;
    let radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    let type = '';
    if (dist > 1) {
      dist = dist * 1.609344;
      type = 'km';
    } else {
      dist = dist * 0.8684;
      type = 'm';
    }

    return { dist: dist, type: type };
  }

  /**
   * 위도, 경도로 거리를 구하는 함수 2
   * @param lat1 내 현재위치 (위도) {latitude: Number}
   * @param lon1 내 현재위치 (경도) {longitude: Number}
   * @param lat2 리스트 현재위치 (위도) {latitude: Number}
   * @param lon2 라스트 목적지위치 (경도) {longitude: Number}
   *
   * 2020-06-03 이재훈
   */
  measure(lat1, lon1, lat2, lon2) {
    var R = 6378.137;
    var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    var meter = Math.floor(d * 1000);
    var km = Math.floor(meter / 1000);
    let type = '';
    if (km > 1) {
      type = 'km';
    } else {
      type = 'm';
    }
    return { meter: meter, km: km, type: type };
  }

  /**
   * 생년으로 올해 나이를 구하는 함수
   * @param birthYear 생년 (number)
   *
   * 2020-07-21 정재은
   */
  calcAge(birthYear) {
    let nowYear = new Date().getFullYear();
    let age = nowYear - birthYear + 1;
    return age;
  }

  /**
   * date를 포맷하는 함수 (new Date()) => 2020-07-20
   *
   * @param date 날짜
   *
   * 2020-07-21 정재은
   */
  formatDate(date): string {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('.');
  }

  formatDate2(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  formatDate3(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return `${[month, day].join('-')} (${WEEKDAY[d.getDay()]})`;
  }

  formatDate4(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return `${[year, month, day].join('-')} (${WEEKDAY[d.getDay()]})`;
  }

  /**
   * var list = {"you": 100, "me": 75, "foo": 116, "bar": 15};
   * [{key:"bar", value:15}, {key:"me", value:75}, {key:"you", value:100}, {key:"foo", value:116}]
   * object 정렬 함수
   *
   * @param obj 정렬할 obj
   *
   * 2020-07-23 정재은
   */
  sortObject(obj) {
    var arr = [];
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        arr.push({
          key: prop,
          value: obj[prop],
        });
      }
    }
    arr.sort(function (a, b) {
      return b.value - a.value;
    });
    return arr; // returns array
  }

  /**
   * 배열 중 제일 큰 값의 index를 리턴하는 함수
   * @param arr 정렬할 배열
   *
   * 2020-07-23 정재은
   */
  indexOfMax(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }
    return maxIndex;
  }

  /**
   *
   * 날짜를 요일로 변환해주는 함수
   * @param date 요일을 구할 날짜
   *
   *  2020-08-27 박서연
   */
  getDayFormat(date) {
    var week = ['일', '월', '화', '수', '목', '금', '토'];
    var dayOfWeek = week[new Date(date).getDay()];
    return dayOfWeek;
  }

  /**
   *
   * 해당 날짜를 주차로 변환해주는 함수
   * 2020-09-24
   *
   * @param dateFormat 날짜
   */
  weekNumberByMonth(dateFormat) {
    const inputDate = new Date(dateFormat);
    let year = inputDate.getFullYear();
    let month = inputDate.getMonth() + 1;

    const weekNumberByThurFnc = paramDate => {
      const year = paramDate.getFullYear();
      const month = paramDate.getMonth();
      const date = paramDate.getDate();

      const firstDate = new Date(year, month, 1);
      const lastDate = new Date(year, month + 1, 0);
      const firstDayOfWeek = firstDate.getDay() === 0 ? 7 : firstDate.getDay();
      const lastDayOfweek = lastDate.getDay();

      const lastDay = lastDate.getDate();

      const firstWeekCheck = firstDayOfWeek === 5 || firstDayOfWeek === 6 || firstDayOfWeek === 7;
      const lastWeekCheck = lastDayOfweek === 1 || lastDayOfweek === 2 || lastDayOfweek === 3;
      const lastWeekNo = Math.ceil((firstDayOfWeek - 1 + lastDay) / 7);
      let weekNo: any = Math.ceil((firstDayOfWeek - 1 + date) / 7);
      if (weekNo === 1 && firstWeekCheck) weekNo = 'prev';
      else if (weekNo === lastWeekNo && lastWeekCheck) weekNo = 'next';
      else if (firstWeekCheck) weekNo = weekNo - 1;
      return weekNo;
    };

    let weekNo: any = weekNumberByThurFnc(inputDate);
    if (weekNo === 'prev') {
      const afterDate = new Date(year, month - 1, 0);
      year = month === 1 ? year - 1 : year;
      month = month === 1 ? 12 : month - 1;
      weekNo = weekNumberByThurFnc(afterDate);
    }
    if (weekNo === 'next') {
      year = month === 12 ? year + 1 : year;
      month = month === 12 ? 1 : month + 1;
      weekNo = 1;
    }

    return { year, month, weekNo };
  }

  /**
   *  객체배열 그룹화하기
   * @param objectArray 배열
   * @param property  그룹화 기준
   */

  groupBy(objectArray, property) {
    return objectArray?.reduce(function (accumulator, currentObj) {
      var key = currentObj[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(currentObj);

      return accumulator;
    }, {});
  }

  groupByToObject(objectArray, property) {
    return objectArray.reduce(function (accumulator, currentObj) {
      var key = currentObj[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key] = currentObj;

      return accumulator;
    }, {});
  }

  /**
   *
   * 해당 날짜와의 차이를 형식에 맞게 변환해주는 함수
   * 2020-09-24
   *
   * @param createdAt 날짜(date().toISOString() 형식)
   */
  displayedAt(createdAt) {
    const milliSeconds = new Date().getTime() - moment(createdAt).valueOf();
    const seconds = milliSeconds / 1000;
    if (seconds < 60) return `방금 전`;
    const minutes = seconds / 60;
    if (minutes < 60) return `${Math.floor(minutes)}분 전`;
    const hours = minutes / 60;
    if (hours < 24) return `${Math.floor(hours)}시간 전`;
    const days = hours / 24;
    if (days < 7) return `${Math.floor(days)}일 전`;
    const weeks = days / 7;
    if (weeks < 5) return `${Math.floor(weeks)}주 전`;
    const months = days / 30;
    if (months < 12) return `${Math.floor(months)}개월 전`;
    const years = days / 365;
    return `${Math.floor(years)}년 전`;
  }

  randomId() {
    var length = 4;
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text + new Date().getTime();
  }

  /**
   *
   * 가격, 숫자 등 기준(,) 포맷 시켜주는 함수
   * @param price 가격
   *  예 ) 100000 => 100,000
   *
   *  2021-06-04 이재훈
   */
  numberFormat(price) {
    const korean = new Intl.NumberFormat('ko');
    return korean.format(price);
  }

  /**
   *
   * @param user userInfo
   * @param id customerUid
   *
   * true면 nickName
   * false면 displayNickname
   */
  checkArray(user, id) {
    if (user?.userType == 'company') {
      return user.tradeUsers.indexOf(id) > -1 ? user?.nickName || '' : user?.displayName || '';
    } else {
      return user?.nickName;
    }
  }

  checkViewSwitch(type, users, id) {
    if (type == 'company') {
      return users.indexOf(id) > -1 ? true : false;
    } else {
      return false;
    }
  }

  /**
   * d-day를 계산하는 함수 (오늘 날짜로부터)
   * @param dday 계산할 d-day
   */
  public counter(dday: Date): number {
    var today = new Date();
    var ryan = dday;
    var interval = today.getTime() - ryan.getTime();
    return Math.floor(interval / (1000 * 60 * 60 * 24)) * -1;
  }

  // 스토리지를 날짜별로 그룹화
  setGroupDataFormat(storages: Array<any>): Array<any> {
    const groupBy = (data, key) => {
      return data.reduce((carry, el) => {
        var group = el[key];

        if (carry[group] === undefined) {
          carry[group] = [];
        }

        carry[group].push(el);
        return carry;
      }, {});
    };

    storages = this.getSortNewDesc(storages);
    const dateGroupData = groupBy(storages, 'dateFormat');
    const keys: Array<string> = Object.keys(dateGroupData);
    let keyData: Array<any> = [];

    keys.forEach(date => {
      const value: Array<Storage> = dateGroupData[date];
      keyData.push({
        date: date,
        value,
      });
    });

    keyData = this.getSortDateFormat(keyData);

    return keyData;
  }

  setGroupDataFormat2(storages: Array<any>): Array<any> {
    const groupBy = (data, key) => {
      return data.reduce((carry, el) => {
        var group = el[key];

        if (carry[group] === undefined) {
          carry[group] = [];
        }

        carry[group].push(el);
        return carry;
      }, {});
    };

    storages = this.getSortNewDesc(storages);
    const dateGroupData = groupBy(storages, 'resvDate');
    const keys: Array<string> = Object.keys(dateGroupData);
    let keyData: Array<any> = [];

    keys.forEach(date => {
      const value: Array<Storage> = dateGroupData[date];
      keyData.push({
        date: date,
        value,
      });
    });

    keyData = this.getSortDateFormat(keyData);

    return keyData;
  }

  //날짜 바뀜
  refreshDate(date) {
    let day;
    switch (moment(date).format('e')) {
      case '0':
        day = '일요일';
        break;
      case '1':
        day = '월요일';
        break;
      case '2':
        day = '화요일';
        break;
      case '3':
        day = '수요일';
        break;
      case '4':
        day = '목요일';
        break;
      case '5':
        day = '금요일';
        break;
      case '6':
        day = '토요일';
        break;
    }

    return moment(date).format('YYYY년 M월 D일 ' + day);
  }

  // 날짜 오름차순
  getSortNew(items: Array<any>): Array<any> {
    return items.sort((a, b) => {
      if (a.dateCreated > b.dateCreated) {
        return 1;
      }
      if (a.dateCreated < b.dateCreated) {
        return -1;
      }
      return 0;
    });
  }

  // 날짜 내림차순
  getSortNewDesc(items: Array<any>): Array<any> {
    return items.sort((a, b) => {
      if (a.dateCreated > b.dateCreated) {
        return -1;
      }
      if (a.dateCreated < b.dateCreated) {
        return 1;
      }
      return 0;
    });
  }

  getSortDateFormat(items: Array<any>): Array<any> {
    return items.sort((a, b) => {
      if (a.date > b.date) {
        return -1;
      }
      if (a.date < b.date) {
        return 1;
      }
      return 0;
    });
  }

  dateFormation(date) {
    let tmp = moment(date).format('a');
    return tmp == 'am' || tmp == '오전' ? moment(date).format('오전 ' + 'h:mm') : moment(date).format('오후 ' + 'h:mm');
  }

  getReserTimeFormat(resvTime): string {
    if (resvTime < 12) {
      return `오전 ${resvTime}-${resvTime + 1}시`;
    }

    if (resvTime == 12) {
      return `오후 12-1시`;
    }

    return `오후 ${resvTime - 12}-${resvTime - 11}시`;
  }

  /**
   *
   * @param date m월 d일 D
   * @returns
   */
  public setDateFormatCasting(date: Date): string {
    return `${date.getMonth() + 1}월 ${date.getDate()}일 ${WEEKDAY[date.getDay()]}`;
  }

  /**
   * 날짜 포맷터를 Date로 변환
   * @param date
   * @param meridiem
   * @param hour
   * @param minute
   * @returns
   */
  setSelectCastingDate(date: number, meridiem: '오전' | '오후', hour: number, minute: number): Date {
    const tmpDate: Date = new Date(date);
    const newDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), hour, minute, 0);

    // 오후일 경우엔 12시간 더하기
    if (meridiem == '오전' && newDate.getHours() == 12) {
      newDate.setHours(newDate.getHours() - 12);
    }

    if (meridiem == '오후' && newDate.getHours() != 12) {
      newDate.setHours(newDate.getHours() + 12);
    }

    return newDate;
  }

  /**
   * 날짜 데이트에서 html 포맷터로 2021-09-23 (목) 오전 10:00
   * @param date
   * @returns
   */
  getSelectCastingDate(date: string): string {
    const tmpDate = new Date(date);
    return `${this.formatDate2(tmpDate)} (${this.getDayFormat(tmpDate)}) ${this.dateFormation(tmpDate)}`;
  }

  /**
   * 날짜 데이트에서 html 포맷터로 오전 10:00
   * @param date
   * @returns
   */
  getSelectCastingDate2(date: string): string {
    const tmpDate = new Date(date);
    return `${this.dateFormation(tmpDate)}`;
  }

  // dday 남은 날짜 구하기
  diffDay2(startDate: string): string {
    const startDateTmp: Date = new Date(startDate);
    const Dday = new Date(startDateTmp.getFullYear(), startDateTmp.getMonth(), startDateTmp.getDate(), 0, 0, 0);
    const today = new Date();
    const now = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);

    var gap = now.getTime() - Dday.getTime(); // 현재 날짜에서 D-day의 차이를 구한다.
    var result = Math.floor(gap / (1000 * 60 * 60 * 24)) * -1; // gap을 일(밀리초 * 초 * 분 * 시간)로 나눈다. 이 때 -1 을 곱해야 날짜차이가 맞게 나온다.

    if (result > 0) {
      return `D-${result}일`;
    }

    if (result == 0) {
      return 'D-Day';
    }

    return `D+${result * -1}일`;
  }

  // dday 남은 날짜 구하기
  diffDay3(startDate: string): string {
    const startDateTmp: Date = new Date(startDate);
    const Dday = new Date(startDateTmp.getFullYear(), startDateTmp.getMonth(), startDateTmp.getDate(), 0, 0, 0);
    const today = new Date();
    const now = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);

    var gap = now.getTime() - Dday.getTime(); // 현재 날짜에서 D-day의 차이를 구한다.
    var result = Math.floor(gap / (1000 * 60 * 60 * 24)) * -1; // gap을 일(밀리초 * 초 * 분 * 시간)로 나눈다. 이 때 -1 을 곱해야 날짜차이가 맞게 나온다.
    
    if (result > 0) {
      return `D-${result}`;
    }

    if (result == 0) {
      return 'D-Day';
    }

    return `D+${result * -1}`;
  }

  diffDay4(startDate: string): string {
    const startDateTmp: Date = new Date(startDate);
    const Dday = new Date(startDateTmp.getFullYear(), startDateTmp.getMonth() + 1, startDateTmp.getDate(), 0, 0, 0);
    const today = new Date();
    const now = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate(), 0, 0, 0);

    var gap = now.getTime() - Dday.getTime(); // 현재 날짜에서 D-day의 차이를 구한다.
    var result = Math.floor(gap / (1000 * 60 * 60 * 24)) * -1; // gap을 일(밀리초 * 초 * 분 * 시간)로 나눈다. 이 때 -1 을 곱해야 날짜차이가 맞게 나온다.
    if (result > 0) {
      return `D+${result}`;
    }

    if (result == 0) {
      return 'D-Day';
    }

    return `D-${result * -1}`;
  }

  // 날짜 더하기
  public addDays(date: string, days: number): Date {
    var result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  }

  // 원화 to 한글
  wonToKorean(num: string) {
    if (num == null || num == '') num = '0';
    num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + '';
    if (num == '0') return '영';
    let number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
    let unit = ['', '만', '억', '조'];
    let smallUnit = ['천', '백', '십', ''];
    let result = [];
    let unitCnt = Math.ceil(num.length / 4);
    num = num.padStart(unitCnt * 4, '0');
    let regexp = /[\w\W]{4}/g;
    let array = num.match(regexp);
    for (let i = array.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
      let hanValue = _makeHan(array[i]);
      if (hanValue == '') continue;
      result.unshift(hanValue + unit[unitCnt]);
    }
    function _makeHan(text: any) {
      let str = '';
      for (let i = 0; i < text.length; i++) {
        let num = text[i];
        if (num == '0')
          //0은 읽지 않는다
          continue;
        str += number[num] + smallUnit[i];
      }
      return str;
    }
    return result.join('');
  }

  //랜덤 정렬
  shuffleArray(array) {
    var m = array.length, t, i;
 
    while (m) {    
     i = Math.floor(Math.random() * m--);
     t = array[m];
     array[m] = array[i];
     array[i] = t;
    }
 
   return array;
 }

 //second to hhmmss
  pad(num) {
    return ("0"+num).slice(-2);
  }
  hhmmss(secs) {
    var minutes = Math.floor(secs / 60);
    secs = secs%60;
    var hours = Math.floor(minutes/60)
    minutes = minutes%60;
    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
  }

  // 로딩 프로세스
  public loaderProcess(snapshot) {
    const uploaded = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    const elem = document.querySelector("div.loading-wrapper div.loading-content");
    if(elem) elem.innerHTML = uploaded.toString() + '%';
    else {
      const loaderDocument = document.querySelector("div.loading-wrapper");
      if(loaderDocument) {
        const loaderContent = document.createElement("div");
        loaderContent.innerHTML = "<div class='loading-content sc-ion-loading-ios'></div>";
        loaderDocument.append(loaderContent);
        const elem = document.querySelector("div.loading-wrapper div.loading-content");
        if(elem) elem.innerHTML = uploaded.toString() + '%';
      }
    }
  }
}
