import { MbscModule } from '@mobiscroll/angular';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ImageService } from './services/image.service';
import { GlobalService } from './services/global.service';
// import { QuillModule } from 'ngx-quill';

// plugin
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { File } from '@ionic-native/file/ngx';
import { Media } from '@ionic-native/media/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { VideoEditor } from '@ionic-native/video-editor/ngx';
import { PhotoLibrary } from '@awesome-cordova-plugins/photo-library/ngx';
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';

// 소셜 로그인
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { KakaoCordovaSDK } from 'kakao-sdk/ngx';
import { NaverCordovaSDK } from 'naver-sdk-ngx/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';

import { environment } from 'src/environments/environment'; 

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    MbscModule,
    FormsModule,
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios', swipeBackEnabled: false }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatExpansionModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    HttpClientJsonpModule,
    HttpClientModule,
  ],
  providers: [
    MobileAccessibility,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Clipboard,
    AppVersion,
    InAppBrowser,
    Camera,
    ImageService,
    SocialSharing,
    NativeAudio,
    MediaCapture,
    File,
    Media,
    Base64,
    AndroidPermissions,
    VideoEditor,
    PhotoLibrary,
    Base64ToGallery,
    HTTP,
    Insomnia,
    Device,
    Geolocation,
    Deeplinks,
    InAppPurchase2,
    SignInWithApple,
    KakaoCordovaSDK,
    NaverCordovaSDK,
    Facebook,
    GooglePlus,
    GlobalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
