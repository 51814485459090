import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginChkEventService {
  private event = new Subject<any>();

  // 이벤트 전달
  publishSomeData(data: any) {
    this.event.next(data);
  }

  // 이벤트 선언
  getObservable(): Subject<any> {
    this.event.subscribe(data => {});
    return this.event;
  }
}
