/** @format */

import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { DbService } from './db.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Device } from '@ionic-native/device/ngx';
declare let iosrtc;
declare let apiRTC;

@Injectable({
  providedIn: 'root',
})
export class ApiRtcService {
  isAndroid: boolean = false;
  isPause: boolean = false;
  isInvitation: boolean = false;
  isCalling: boolean = false;
  invitation;
  callId;
  infoLabel: any;
  ua;
  userId: string = '';
  isReady;
  connectedSession;
  rtcNumber;
  hospitalIds$ = new BehaviorSubject([]);
  userIds = [];
  contact;
  call;
  mystream;
  remotestream;
  constructor(private platform: Platform, public permission: AndroidPermissions, private navc: NavController,private device: Device,) {}

  // 앱에 접속하면 접속중인 user들 저장!
  // 내가 접속했다 라고 api rtc 저장하는 함수
  async ininRTc() {
    if (!this.isReady) {
      this.platform.ready().then(() => {
        if (this.platform.is('android')) {
          this.isAndroid = true;
          if(parseInt(this.device.version) >= 13) {
            this.requestPermissions(['WRITE_EXTERNAL_STORAGE', 'RECORD_AUDIO', 'MODIFY_AUDIO_SETTINGS', 'READ_PHONE_STATE', 'CAMERA']);
          }else {
            this.requestPermissions(['WRITE_EXTERNAL_STORAGE', 'RECORD_AUDIO', 'MODIFY_AUDIO_SETTINGS', 'READ_PHONE_STATE', 'CAMERA']);
          }
        }
        this.callInvitationProcess = this.callInvitationProcess.bind(this);
        this.setCallListeners = this.setCallListeners.bind(this);
        this.sessionReadyHandler = this.sessionReadyHandler.bind(this);

        this.ua = new apiRTC.UserAgent({
          uri: 'apzkey:eb463b037217fce84bbf3ad6c02c15ce',
        });

        this.userId = localStorage.getItem('userId');

        // 230621 FIX 비정상접근
        if (!this.ua) {
          return;
        }

        return this.ua
          .register({
            id: this.userId,
            userAcceptOnIncomingScreenSharingCall: true,
          })
          .then(session => {
            this.isReady = true;
            this.connectedSession = session;
            this.rtcNumber = session.id;
            this.sessionReadyHandler(this.connectedSession);
          })
          .catch(error => {});
      });
    }
  }

  /**
   * 로그아웃 시 api rtc 종료
   */
  unregister() {
    // 230621 FIX 비정상접근
    if (!this.ua) {
      return;
    }

    this.ua
      .unregister({
        id: localStorage.getItem('userId'),
      })
      .then(success => {
        this.ua = null;
        this.isCalling = false;
        this.invitation = null;
        this.callId = '';
        this.infoLabel = null;
        this.connectedSession = null;
        this.rtcNumber = null;
        this.contact = null;
        this.call = null;
        this.mystream = null;
        this.remotestream = null;
      })
      .catch(error => {});
      
  }

  private requestPermissions(permission: string[], num?: number) {
    var task = num;
    if (num === undefined) task = permission.length - 1;
    var req = 'android.permission.' + permission[task];
    this.permission.checkPermission(req).then(
      v => {
        if (v.hasPermission && task != 0) this.requestPermissions(permission, task - 1);
        else
          this.permission
            .requestPermission(req)
            .then(
              v => {},
              e => {}
            )
            .then(v => {
              if (task != 0) this.requestPermissions(permission, task - 1);
            });
      },
      e => {}
    );
  }

  callInvitationProcess(invitation) {
    if (this.isPause) {
      this.ringing();
    }

    this.invitation = invitation;
    invitation.on('statusChange', statusChangeInfo => {
      console.error('statusChangeInfo :', statusChangeInfo);
      if (statusChangeInfo.status === apiRTC.INVITATION_STATUS_EXPIRED) {
        console.error('INVITATION_STATUS_EXPIRED');
      }
    });

    this.showAcceptDeclineButtons();
  }

  showAcceptDeclineButtons() {
    this.isInvitation = true;
    const celebInfo = this.invitation.sender.userData;
    const type: string = 'user';
    const heartUsageId: string = celebInfo.heartUsageId;
    const celebId: string = celebInfo.id;

    this.navc.navigateForward('/videocall', {
      animated: false,
      queryParams: {
        type,
        heartUsageId,
        celebId,
      },
    });
  }

  // 통화승낙
  accept() {
    if (this.invitation.getCallType() === 'audio') {
      const answerOptions = {
        mediaTypeForIncomingCall: 'AUDIO',
      };
      this.invitation.accept(null, answerOptions).then(call => {
        this.setCallListeners(call);
        this.callId = call.getId();
        this.isCalling = true;
        this.refreshVideoView();
      });
    } else {
      this.invitation
        .accept({
          facingMode: 'environment',
        })
        .then(call => {
          this.setCallListeners(call);
          this.callId = call.getId();
          this.isCalling = true;
        });
    }
    this.hideAcceptDeclineButtons();
  }

  hideAcceptDeclineButtons() {
    this.isInvitation = false;
  }

  // 통화거절
  decline() {
    this.invitation.decline();
    this.hideAcceptDeclineButtons();
  }

  // 통화끊기
  hangupCall() {
    this.isCalling = false;
    if (this.connectedSession) {
      const call = this.connectedSession.getCall(this.callId);
      if (call) {
        call.hangUp();
      }
    }
  }

  /**
   * 자신의 화면을 가릴지 바꾸는 function
   * @param type true : 화면보여줌 | false : 화면가림
   */
  screenShareToggle(type: boolean) {
    this.ua.setUsername(type);

    this.connectedSession.setUserData({ username: type });
  }

  refreshVideoView() {
    if (this.platform.is('ios')) {
      iosrtc.refreshVideos();
    }
  }

  ringing() {}

  setCallListeners(call) {}

  // 영상통화가 이루어졌을때
  sessionReadyHandler(connectedSession) {
    this.infoLabel = 'Your local ID : ' + apiRTC.session.apiCCId;

    // 상대방 정보가 바뀌었을때!
    connectedSession.on('contactListUpdate', updatedContacts => {
      this.updateAddressBook();

      // 상대방이 영상통화 소리를 껐을때
      if (document.getElementById('remote')) {
        const remote: any = document.getElementById('remote').childNodes[0];
        if (updatedContacts.userDataChanged[0].userData.speak == 'true') {
          remote.muted = false;
        } else {
          remote.muted = true;
        }
      }
    });

    // 전화 왔을때
    connectedSession.on('incomingCall', invitation => {
      this.callInvitationProcess(invitation);
    });

    connectedSession.on('incomingScreenSharingCall', call => {
      this.setCallListeners(call);
      this.callId = call.getId();
      this.isCalling = true;
    });

    connectedSession.on('incomingScreenSharingCallInvitation', invitation => {
      this.callInvitationProcess(invitation);
    });
  }

  updateAddressBook() {
    let contactListArray = this.connectedSession.getOnlineContactsArray(),
      i = 0;
    const ids = [];

    for (i = 0; i < contactListArray.length; i += 1) {
      const user = contactListArray[i];
      if (user.getId() !== apiRTC.session.apiCCId) {
        const userData = user.getUserData();
        ids.push(userData);
      }
    }
    this.userIds = ids;
    this.hospitalIds$.next(ids);
  }

  facingMode: string = '';

  // 스트림 다시 만듦 (화면 바꿀 때!)
  // 전면후면은 (facingMode) 스트림을 끊은 후 다시 만들어야 함!
  async createStream(facingMode: string): Promise<any> {
    this.facingMode = facingMode;
    if (this.mystream != null) {
      this.mystream.release();
    }

    const stream = await this.getStream();
    return new Promise((resolve, reject) => {
      this.call
        .replacePublishedStreams(null, this.mystream)
        .then(stream => {
          resolve(this.mystream);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async getStream(): Promise<void> {
    return new Promise((resolve, reject) => {
      var createStreamOptions = {
        facingMode: this.facingMode,
      };

      this.ua
        .createStream(createStreamOptions)
        .then(async stream => {
          this.mystream = stream;
          resolve(this.mystream);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
