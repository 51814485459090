import { Injectable } from '@angular/core';
import { DbService, leftJoinDocument } from './db.service';
import OneSignal from 'onesignal-cordova-plugin';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { CommonService } from './common.service';
import { Alarm } from '../models/alarm.model';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  constructor(public db: DbService, public http: HttpClient, public plt: Platform, private common: CommonService) {}

  /**
   * 토큰 값 (pushId) 받는 함수
   */

  async getId(): Promise<any> {
    if (this.plt.is('cordova')) {
      return new Promise<{ userId: string }>(resolve => {
        OneSignal.getDeviceState((userId: any) => {
          resolve(userId);
        });
      });
    }
  }

  async getPushId() {
    if (this.plt.is('cordova')) {
      let token = await this.getId();
      return token;
    } else {
      return { userId: '' };
    }
  }

  //사용자 앱 푸시
  async sendPush(pushIds: Array<string>, msg: string, data: any) {
    return new Promise(async (resolve, reject) => {
      var headers = {
        'Content-Type': 'application/json; charset=utf-8',
      };

      var message = {
        app_id: 'e60e6253-4046-42ee-8b7e-d93edf6cf2b2',
        contents: { en: msg },
        headings: { en: '포롱' },
        include_player_ids: pushIds,
        data: data,
        android_channel_id: '4e242b05-40be-4a05-9aa3-30e209a39a4c',
        ios_badgeType: 'Increase',
        ios_badgeCount: 1,
      };

      var options = {
        host: 'onesignal.com',
        port: 443,
        path: '/api/v1/notifications',
        method: 'POST',
        headers: headers,
      };

      this.http.post('https://onesignal.com/api/v1/notifications', message, options).subscribe(
        new_data => {
          resolve(new_data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  async sendPrivateMsgToCelebPush(celebId: string, type: string, heartUsageId: string): Promise<void | boolean> {
    const celeb = await this.db.doc$(`user/${celebId}`).pipe(first()).toPromise();

    if(celeb) {
      this.sendAlarm(celebId, type, heartUsageId, '');
    }

    //관리계정으로도 알림 발송
    const managers = await this.db.collection$(`celebManager`, ref => ref.where('celebId', '==', celebId))
    .pipe(leftJoinDocument(this.db.afs, 'userId', 'user'))
    .pipe(first())
    .toPromise();
    
    managers.forEach(manager => {
      this.sendAlarm(manager.userId.uid, type, heartUsageId, '');
      if (manager.userId.pushAlarm && manager.userId.pushId) {
        this.sendPush([manager.userId.pushId], '관리 셀럽의 다이렉트메시지 신청이 있습니다!', {
          type: 'friend',
        });
      }
    });

    if (!celeb || !celeb.chatAlarm || !celeb.pushAlarm || !celeb.pushId) {
      return false;
    }

    this.sendPush([celeb.pushId], '다이렉트메시지 신청이 있습니다!', {
      type: 'friend',
    });
  }

  async sendCastingUserPush(userId: string, castingId: string, type: 'reply' | 'complete' | 'confirm' | 'reject' | 'edit' | 'contractDoc' | 'contractDocEdit' | 'contractDocConfirm') {
    const user = await this.db.doc$(`user/${userId}`).pipe(first()).toPromise();
    this.sendAlarm(userId, type, castingId, '');

    if (!user || !user.pushAlarm || !user.pushId) {
      return false;
    }

    let msg: string = '';

    switch (type) {
      case 'reply': {
        msg = '확인하지 않은 댓글이 있습니다.';
        break;
      }

      case 'confirm': {
        msg = '캐스팅 승인이 완료되었습니다.';
        break;
      }

      case 'complete': {
        msg = '캐스팅이 완료되었습니다.';
        break;
      }

      case 'reject': {
        msg = '셀럽의 사정으로 캐스팅을 진행할 수 없습니다.';
      }

      case 'edit': {
        msg = '셀럽이 캐스팅 정보를 수정하였습니다.';
        break;
      }

      case 'contractDoc': {
        msg = '셀럽이 계약서를 작성하였습니다.';
        break;
      }

      case 'contractDocEdit': {
        msg = '셀럽이 계약서를 수정하였습니다.';
        break;
      }

      case 'contractDocEdit': {
        msg = '셀럽이 계약서를 확정하였습니다.';
        break;
      }
    }

    this.sendPush([user.pushId], msg, {
      type,
      castingId,
    });
  }

  async sendCastingCelebPush(castingType: string, celebId: string, castingId: string, type: 'inquiry' | 'reply' | 'confirm' | 'complete' | 'contractDoc' | 'contractDocEdit' | 'edit') {
    const celeb = await this.db.doc$(`user/${celebId}`).pipe(first()).toPromise();
    this.sendAlarm(celebId, type, castingId, castingType);

    let msg: string = '';

    switch (type) {
      case 'inquiry': {
        msg = `캐스팅 문의(${castingType})가 있습니다.`;
        break;
      }

      case 'reply': {
        msg = '확인하지 않은 댓글이 있습니다.';
        break;
      }

      case 'edit': {
        msg = '회원이 캐스팅 정보를 수정하였습니다.';
        break;
      }
      
      case 'confirm': {
        msg = '캐스팅 승인이 완료되었습니다.';
        break;
      }

      case 'complete': {
        msg = '캐스팅이 완료되었습니다.';
        break;
      }

      case 'contractDoc': {
        msg = '회원이 계약서를 작성하였습니다.';
        break;
      }

      case 'contractDocEdit': {
        msg = '회원이 계약서를 수정하였습니다.';
        break;
      }
    }

    //관리계정으로도 알림 발송
    const managers = await this.db.collection$(`celebManager`, ref => ref.where('celebId', '==', celebId))
    .pipe(leftJoinDocument(this.db.afs, 'userId', 'user'))
    .pipe(first())
    .toPromise();

    managers.forEach(manager => {
      this.sendAlarm(manager.userId.uid, 'celeb_'+type, castingId, castingType);
      if (manager.userId.pushAlarm && manager.userId.pushId) {
        this.sendPush([manager.userId.pushId], '관리 셀럽의 ' + msg, {
          type,
          castingId,
        });
      }
    });

    if (!celeb || !celeb.pushAlarm || !celeb.pushId) {
      return false;
    }

    this.sendPush([celeb.pushId], msg, {
      type,
      castingId,
    });
  }

  /**
   * 알림 전송 서비스
   * @param userId 알림을 보낼 userId
   * @param type post | friend | updateProfile
   * @param chatId 메세지를 보내는 경우엔 chatId!
   * @returns
   */
  public async sendAlarm(userId: string, type: string, chatId?: string, castingType?: string): Promise<any> {
    const alarm: Alarm = {
      id: this.common.generateFilename30(),
      userId,
      type,
      chatId,
      dateCreated: new Date().toISOString(),
      castingType: castingType,
      view: false,
    };
    const userType = localStorage.getItem('usertype');
    await this.db.updateAt(`alarm/${alarm.id}`, alarm);
    return 'success';
  }
}
